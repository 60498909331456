/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import { Link, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Box, Columns, Section } from 'react-bulma-components'
import Meta from 'components/Meta/Meta'

// Gatsby SEO Head
export function Head({ data }) {
  return (
    <Meta tags={data.page.seoMetaTags.tags}>
      <script type="application/ld+json" className="yoast-schema-graph">
        {`
        "@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://pocketworks.co.uk/#website","url":"https://pocketworks.co.uk/","name":"Pocketworks","description":"UK Mobile App Development Company","potentialAction":[{"@type":"SearchAction","target":"https://pocketworks.co.uk/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://pocketworks.co.uk/#webpage","url":"https://pocketworks.co.uk/","name":"Pocketworks | App Developer | App Companies UK","isPartOf":{"@id":"https://pocketworks.co.uk/#website"},"datePublished":"2018-01-01T07:00:06+00:00","dateModified":"2021-01-01T07:00:06+00:00","description":"Pocketworks - UK app developers - one of the UK\u2019s leading mobile app development companies. Offering research-led design and a specialty in UX, we deliver over £100m in revenue for our clients. Our cross-functional team of designers and developers provides expertise in user research, design, iOS apps, Android apps, web apps and Flutter apps.","breadcrumb":{"@id":"https://pocketworks.co.uk/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://pocketworks.co.uk/"]}]},{"@type":"BreadcrumbList","@id":"https://pocketworks.co.uk/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebPage","@id":"https://pocketworks.co.uk/","url":"https://pocketworks.co.uk/","name":"Home"}}]}]
      `}
      </script>
    </Meta>
  )
}

class SparkApp extends React.Component {
  

  constructor(props) {
    super(props)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.findSparks = this.findSparks.bind(this)
    
    this.state = {
      mode: 'welcome',
      sparks: [],
      // sparks: [
      //   '1. A mindfulness app for people with diabetes, helping them to focus on the present moment and manage their stress levels.',
      //   '2. A meditation app specifically for people with diabetes, to help them relax and focus on their breathing.',
      //   '3. An app to help people with diabetes track their blood sugar levels and monitor their condition.',
      //   '4. A nutrition app for people with diabetes, to help them make healthy food choices and improve their overall wellbeing.',
      //   '5. An exercise app for people with diabetes, to help them stay active and improve their fitness levels.',
      //   '6. An exercise app for people with diabetes, to help them stay active and improve their fitness levels.',
      // ],
    }
  }

  componentDidMount() {
    const startButton = document.querySelector('#start-button')
    const generateButton = document.querySelector('#generate-button')

    startButton.addEventListener('click', () => {
      this.setState(() => ({
        mode: 'app',
      }))
    })

    generateButton.addEventListener('click', () => {
      this.findSparks() 
    })
    
  }
  
  onKeyDown(event){
    console.log(event)
    if(event.key === 'Enter'){
      this.findSparks()    
    }
  }

  findSparks(){
    document.querySelector('#please-wait').classList.remove('is-hidden')
      this.setState(() => ({
        sparks: [],        
      }))
      const userInput = document.querySelector('#user-input').value
      document.querySelector('#user-input').classList.add('is-loading')
      fetch('/.netlify/functions/spark', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ input: userInput }),
      })
        .then((response) => {
          const json = response.json()
          console.log('Got response')
          console.log(json)
          return json
        })
        .then((data) => {
          document.querySelector('#user-input').classList.remove('is-loading')
          document.querySelector('#generate-button').textContent =
            'Generate more'
          document.querySelector('#please-wait').classList.add('is-hidden')
          this.setState(() => ({
            sparks: data.lines || ["Error"],
          }))
        }).catch((error)=>{
          document.querySelector('#please-wait').classList.add('is-hidden')
          this.setState(() => ({
            sparks: [error.message],
          }))
        })
  }

  render() {
    return (
      <div>
        <Section
          id="welcome"
          className={`${
            this.state.mode !== 'welcome' ? 'is-hidden' : ''
          } is-fullheight hero is-fullheight is-dark-bg`}
        >
          <Columns>
            <Columns.Column className="is-5">
              <h3 className="title is-1-desktop is-2-tablet is-5-mobile">
                Pocket<span className="has-text-primary">Spark</span><small><sup>BETA</sup></small>
              </h3>
              <h1 className="title is-light mt-6">
                Rapidly create{' '}
                <span className="has-text-success">
                  fresh app ideas using AI
                </span>
              </h1>
              <p class="title">
                A brainstorming tool that helps business leaders approach problems from new angles.
              </p>
              <a
                id="start-button"
                className="button is-primary is-medium is-leafy mt-3"
              >
                Start generating ideas
              </a>
            </Columns.Column>
            <Columns.Column className="is-7 is-hidden-mobile">
              <StaticImage src="https://www.datocms-assets.com/20955/1669626154-ideas.png" />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column className="is-5-desktop is-size-6">
              <Link to="/"><StaticImage
                src="https://www.datocms-assets.com/20955/1579119892-pwsecondarylogowhite-01-small.png"
                width={150}
                className="mb-3"
              /></Link>
              <p>
                PocketSpark is an experimental tool created for fun and learning. Use at your own risk.
              </p>
              <p className="mt-3">
                Created by <Link to="/">Pocketworks</Link>, a
                design and technology company that helps businesses develop
                mobile apps worth coming back to.
                <br />
              </p>
            </Columns.Column>
          </Columns>
        </Section>
        <Section
          id="app"
          className={`${
            this.state.mode !== 'app' ? 'is-hidden' : ''
          } hero is-fullheight  is-fullheight is-dark-bg`}
        > 
          <Columns>
            <Columns.Column>
              <h3 className="title is-1-desktop is-2-tablet is-5-mobile">
                Pocket<span className="has-text-primary">Spark</span><small><sup>BETA</sup></small>
              </h3>
              <label for="user-input" className="has-text-light">
                What industry are you in?
              </label>
              <br />
              <input
                id="user-input"
                type="text"
                className="input text is-large has-background-dark has-text-light mt-2"
                placeholder="e.g. taxi"
                onKeyDown={this.onKeyDown}
              />
              <a
                id="generate-button"
                className="button is-primary is-leafy is-medium mt-3"
              >
                Generate App Ideas
              </a>
              
            </Columns.Column>
          </Columns>
          <Columns className="">
            <Columns.Column className='is-12 has-text-centered'>
              <Box id="please-wait" className="tag has-background-grey-dark has-text-warning is-size-4-desktop is-size-7-mobile p-6 is-hidden">
                <i class="fa-duotone fa-robot-astromech fa-2xl" /> &nbsp;&nbsp; Droids thinking, please allow 10 seconds...
              </Box> 
            </Columns.Column>
            {this.state.sparks.map((spark) => (
              <Columns.Column key={spark} className="is-4 p-3">
                <Box className="has-background-grey-dark has-text-light">
                  {spark}
                  <p className="has-text-right is-hidden">
                    <i className="fa-light fa-heart" />
                  </p>
                </Box>
              </Columns.Column>
            ))}
          </Columns>
          <p className="has-text-centered is-size-6"><Link to="/blog/introducing-pocketspark">About PocketSpark</Link></p>
        </Section>
      </div>
    )
  }
}

export const query = graphql`
  query SparkQuery {
    page: datoCmsPage(slug: { eq: "spark" }) {
      id
      title
      slug
      excerpt
      featuredMedia {
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { w: "800", fm: "jpg", auto: "compress" }
        )
        alt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

export default SparkApp
